@import 'colors.scss';

.title.title {
  color: #2a3135;
  font: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.emphasized.emphasized {
  font-weight: 600;
}

.subtext {
  font-size: 14px;
  line-height: 20px;
}

.amount {
  text-align: right;
}
