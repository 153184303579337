@use 'utils.scss';
@use 'breakpoints.scss';
@use '../../theme/mixins.scss';

.container {
  --overhang-right: min(5vw, calc(var(--ornament-right, 0px) * -1));
  --overhang-left: min(5vw, calc(var(--ornament-left, 0px) * -1));
  --overhang-bottom: min(10vw, calc(var(--ornament-bottom, 0px) * -1));
  --overhang-top: min(10vw, calc(var(--ornament-top, 0px) * -1));
  --padding-inline: var(--overhang-right);
  --padding-inline-doubled: calc(var(--padding-inline) * 2);

  width: 100%;
  max-width: calc(
    var(--main-content-width, 1060px) + var(--padding-inline-doubled)
  );
  margin: 0 auto;
  padding-top: calc(var(--ornament-top, 0px) * -1);
  background-color: var(--color-background);

  @include breakpoints.from(3) {
    padding-bottom: var(--overhang-bottom);
    padding-inline: var(--padding-inline);
  }
}

.contentRight.container {
  // when the video/ornamentation is on the left use the left overhang as the padding to keep the content centered
  --padding-inline: var(--overhang-left);
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  gap: 1rem;

  @include breakpoints.to(2) {
    flex-direction: column-reverse;

    // when the media is on top we need to account for the ornamentation's bottom overhang to prevent it from overlapping the text content below it.
    gap: calc(var(--overhang-bottom) + 1rem);
  }
}

.contentRight .contentWrapper {
  flex-direction: row-reverse;

  @include breakpoints.to(2) {
    flex-direction: column-reverse;
  }
}

.content {
  max-width: 365px;

  @include breakpoints.from(3) {
    flex: 1 1 365px;
  }

  @include breakpoints.to(2) {
    max-width: calc(534px + var(--overhang-left) + var(--overhang-right));
    margin-top: 40px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: left;
  }
}

.contentRight .content {
  justify-self: end;

  @include breakpoints.from(3) {
    max-width: 408px; // this value is very specific to the current design to get the text to wrap a particular way without affecting the left-aligned ones.
  }
}

.mediaContainer {
  position: relative; // so we can position the ornamentations on/around the media
  margin-left: var(--overhang-left);

  @include breakpoints.from(3) {
    flex: 0 1 534px;
  }

  @include breakpoints.to(2) {
    margin-right: var(--overhang-right);
  }
}

.contentRight .mediaContainer {
  margin-right: var(--overhang-right);
  margin-left: 0;

  @include breakpoints.to(2) {
    // need left AND right when content is stacked (flex-direction: column)
    margin-left: var(--overhang-left);
  }
}

.container :is(img, video) {
  display: block;
}

.media {
  width: 534px;
  max-width: 100%;
  border-radius: 2rem;
}

.markdown {
  @include mixins.body2;

  @include breakpoints.to(2) {
    font-size: 18px;
  }
}
