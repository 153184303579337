@import 'utils.scss';
@import 'breakpoints.scss';

.container.container {
  background-color: var(--color-background);
  color: currentcolor;
}

.container.container:is(.bgClouds, .bgSky) {
  background-color: var(--color-background-primary);
}

.container.bgClouds.container.bgClouds {
  background-image: url('../theme/assets/cloud_med.svg'),
    url('../theme/assets/cloud_med.svg'), url('../theme/assets/cloud_small.svg'),
    url('../theme/assets/cloud_med.svg');
  background-repeat: no-repeat;
  background-position:
    -23px 8%,
    left -23px bottom 37px,
    right -22px top 27%,
    right -139px top 1396px;
  background-size:
    auto,
    auto,
    auto,
    422px auto;

  @include to(2) {
    background-image: url('../theme/assets/cloud_med.svg'),
      url('../theme/assets/cloud_med.svg'), url('../theme/assets/cloud_med.svg');
    background-repeat: no-repeat;
    background-position:
      -54px 234px,
      left -155px bottom 225px,
      right -120px top 710px;
  }
}
