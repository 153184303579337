@import 'breakpoints.scss';
@import 'colors.scss';

.imageWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.imageWrapper img {
  width: 100%;
  height: auto;
  margin: 15px 0;

  @include from(3) {
    max-width: 400px;
  }
}

.planHighlights {
  width: 100%;
  // margin: 58px 0 40px;
  // padding: 0 13px;

  @include from(3) {
    margin-bottom: 0;
  }

  .planTitle {
    // font-size: 18px;
    // font-weight: 800;
    // line-height: 23px;
    // text-align: center;
    color: #330b66;
    font-family: Averta;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  .planList {
    padding-left: 0;
    // margin: 0 0 0 21px;
    // padding: 0;
    color: #2a3135;
    font-family: Averta;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .highlight {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .featured.featured {
    font-weight: 600;
  }
}

.weightLossChartWrapper {
  @include from(3) {
    margin: 15px 0;
    border-radius: 10px;
    background-color: $white;
  }
}

.chartLoading {
  padding: 0;

  img {
    max-width: 170px;
    max-height: 280px;
  }
}
