@import 'breakpoints.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include from(3) {
    height: 66px;
  }
}

.total {
  flex-shrink: 0;

  @include from(3) {
    font-size: 17px;
  }
}

.addPromo {
  padding-right: 40px;
}

.hideAddPromo {
  visibility: hidden;
}

.addPromoForm {
  height: 33px;
  font-size: 14px;
}

.addPromoLink {
  @include from(3) {
    font-size: 17px;
  }
}
