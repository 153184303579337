@import 'breakpoints.scss';

// the chat bubble treatment
.container {
  display: inline-flex;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0.75rem;
  padding-right: 1.6rem;
  border-radius: 1000px;
  background-color: var(--color-background-button-primary);
  color: var(--color-text-button-primary);
  gap: 0.5rem;

  @include to(1) {
    padding: 0.25rem;
    padding-right: 0.75rem;
    gap: 0.5rem;
  }

  @include between(1, 2) {
    padding: 0.5rem;
    padding-right: 1.2rem;
    gap: 0.25rem;
  }
}
