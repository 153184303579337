@import 'colors.scss';

.discount {
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid $border;
}

.label {
  flex-grow: 1;
}

.value {
  font-weight: 600;
}
