@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 31px 20px 0;

  @include to(2) {
    background-color: $white;
  }

  @include from(3) {
    max-width: 579px;
  }
}

.outerContainer {
  flex-grow: 1;
}

.spacer {
  width: 100%;
  margin-top: 20px;
  color: $grey100;
}
