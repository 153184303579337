@use 'breakpoints.scss';
@use '../../theme/mixins.scss';

.container {
  padding: 1rem;
  border-radius: 2rem;
  background-color: #ffffff;
  gap: 1rem;
}

.title {
  @include mixins.body2;

  color: currentcolor;
  font-size: 1.2rem;

  @include breakpoints.to(1) {
    font-size: 12px;
  }

  @include breakpoints.from(4) {
    font-size: var(--font-size-subhead-primary, 24px);
  }
}

.content {
  width: auto;
  height: auto;
}

.image {
  width: 37px;
  height: auto;
  object-fit: contain;
  margin: 0;

  @include breakpoints.to(2) {
    width: 30px;
  }
}
