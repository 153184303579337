.ornamentations {
  display: flex;
  position: absolute;

  // see Feature.scss for the --overhang-* variables
  inset: calc(var(--overhang-top, 0px) * -1)
    calc(var(--overhang-right, 0px) * -1) calc(var(--overhang-bottom, 0px) * -1)
    calc(var(--overhang-left, 0px) * -1);
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

.ornamentations > * {
  max-width: 45%; // scales image ornamentations down as the media narrows on smaller screens
}

.leftFirst.ornamentations > :nth-child(odd),
.rightFirst.ornamentations > :nth-child(even) {
  align-self: start;
}

.leftFirst.ornamentations > :nth-child(even),
.rightFirst.ornamentations > :nth-child(odd) {
  align-self: end;
}

.ornamentations .responsiveImage {
  width: 100%;
  border-radius: min(2vw, 1rem);
  box-shadow: 0 9px 18px 4px rgb(0 0 0 / 20%);
}
