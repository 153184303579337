@import 'breakpoints.scss';
@import 'colors.scss';
@import 'utils.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 31px 20px 0;
}

.outerContainer {
  flex-grow: 1;
}

.cart {
  @include from(3) {
    max-width: 984px;
    margin-bottom: 45px;
  }

  @include to(2) {
    width: 100%;
    margin-bottom: 25px;
  }
}

.item {
  @include from(3) {
    margin-bottom: 20px;
  }
}

.message {
  @include markdownRemoveMargins;

  align-self: center;
  max-width: 300px;
  margin-right: 63px;
  margin-left: 63px;
  color: $grey700;
  text-align: center;

  @include from(3) {
    margin-bottom: 35px;
    font-size: 17px;
    line-height: 23px;
  }

  @include to(2) {
    margin: 0 50px 25px;
  }
}
