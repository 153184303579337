@import 'breakpoints.scss';
@import 'colors.scss';
@import 'utils.scss';

.cartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 20px 70px;
  background: radial-gradient(
    77.95% 133.61% at 50% 50%,
    rgb(211 220 255 / 45%) 0%,
    #d3dcff 100%
  );
}

.logoContainer {
  display: inline-block;
  // margin-top: 20px;
  margin-bottom: 20px;

  .logo {
    width: 170px;
    height: 30px;
    object-fit: contain;
  }
}

.cardContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 335px;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  gap: 20px;

  .twitterBird {
    position: absolute;
    top: -32px;
    left: 87%;
    height: 34px;

    img {
      height: 100%;
    }
  }
}

.priceContainer {
  .priceHeading {
    margin-top: 20px;
    color: #330b66;
    font-family: Averta;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

.items {
  display: grid;
  grid-template-columns: 3fr 1fr;
  width: 100%;
  // margin-bottom: 20px;
  padding-top: 14px;
  color: $grey800;
  font-size: 16px;
  line-height: 20px;
  gap: 28px 15px;
}

.footer {
  display: flex;
  flex-direction: column;
  color: #2a3135;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-align: center;

  p {
    margin: 0;
  }
}
