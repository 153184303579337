@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  .timerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 20px 10px;
    border-radius: 10px;
    background-color: #effcf5;
    text-align: center;
    gap: 20px;
  }
}

.countdownTitle {
  color: #2a3135;
  font-family: Averta;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
}

.countdownInfo {
  color: #2a3135;
  font-family: Averta;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.countdownTimer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
