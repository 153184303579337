@import 'colors.scss';

.section {
  width: 100%;
  margin-top: 20px;
  // padding: 20px;
  border-radius: 10px;
  background-color: $white;
}

.heading {
  margin-bottom: 16px;
  // margin-top: 4px;
  // margin-bottom: 24px;
  // text-align: center;

  color: #330b66;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.presslogosContainer {
  margin-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid $grey75;
  border-bottom: 1px solid $grey75;
}

.pressLogoHeading {
  color: #330b66;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cta {
  align-self: center;
  width: 300px;
  margin: 15px 0 29px;
}

.secureCheckout {
  margin-top: 12px;
}

.passwordHeading {
  line-height: 2;
}

.passwordRules {
  text-align: left;
}
