@import 'breakpoints.scss';
@import 'colors.scss';

.outerContainer {
  color: #2b2529;
  padding-top: 50px;
}

.header {
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  color: var(--color-cool-purple-700);
}

.outerContainer .container {
  padding: 20px;
  border-radius: 10px;
  background-color: $white;
  margin-top: 30px;
}

.gameDetailsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;

  .gameDetails {
    width: 100%;
    max-width: 500px;
  }
}

.paymentForm p {
  max-width: 100%;
  text-align: left;
}

.secureCheckout {
  margin: 0 10px 50px;
  width: 100%;
}
