.container {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  max-width: 35ch;
  margin: 0 auto;
  inset: 0;
  padding: 1rem;
}

.button {
  margin: 1rem 0;
}

.support {
  text-align: center;
}
