@import 'breakpoints.scss';
@import 'colors.scss';
@import 'utils.scss';

.container.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 31px 15px 0;
  background-color: $white;

  @include from(3) {
    max-width: 400px;
    border-radius: 10px;
    background-color: $white;
  }
}

.sectionsWrapper {
  width: 100%;

  @include from(3) {
    max-width: 400px;
  }
}

.lowerContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;

  @include from(3) {
    max-width: 100%;
    background-color: $grey25;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    text-align: left;
  }

  h2 {
    font-size: 18px;
  }
}

.innerContainer {
  padding: 25px 16px 20px;
  border-radius: 10px;
  background-color: $white;

  @include from(3) {
    max-width: 984px;
  }

  @include to(2) {
    width: 100%;
  }
}

.section {
  margin-top: 12px;
}

.today {
  margin-bottom: 21px;
  padding: 0 0.5rem 2rem;
  border-bottom: 1px solid $grey100;
}

.today .heading {
  margin-bottom: 34px;
}

.afterTrial .heading {
  margin-left: 0.5rem;
}

.message {
  @include markdownRemoveMargins;

  align-self: center;
  max-width: 300px;
  margin-right: 63px;
  margin-left: 63px;
  color: $grey700;
  text-align: center;

  @include from(3) {
    margin-bottom: 35px;
    font-size: 17px;
    line-height: 23px;
  }

  @include to(2) {
    margin: 0 50px 25px;
  }
}

.addPromo {
  margin-top: 28px;
}

.addPromoForm {
  height: 33px;
  font-size: 14px;
}

.addPromoLink {
  @include from(3) {
    font-size: 17px;
  }
}

.bottomSpacing {
  margin-bottom: 28px;
}

.outerContainer {
  flex-grow: 1;
}

.spacer {
  width: 100%;
  margin-top: 20px;
  color: $grey100;
}

.savePaymentLabel {
  margin-top: 30px;
}

.logos {
  --row-gap: 32px;

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-evenly;
  margin-top: 25px;

  /*
  iOS Safari < 14.8 doesn't support row-gap for flex so we're using
  bottom margin on the imgs. this pulls up the gap under the last row.
  */
  margin-bottom: calc(var(--row-gap) * -1);

  img {
    @media screen and (width <= 359px) {
      margin: 0 16px var(--row-gap);
    }

    margin: 0 8px var(--row-gap);
  }
}
