@import 'breakpoints.scss';

.discount {
  display: flex;
  align-items: center;

  @include from(3) {
    height: 66px;
    font-size: 17px;
  }
}

.label {
  margin: 0 0.5em 0 auto;
}

.value {
  font-weight: 600;
}
