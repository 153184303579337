@import 'breakpoints.scss';
@import 'utils.scss';

.description {
  @include clamp(2);

  max-height: 40px;
  margin: 0 0 17px;
  overflow: hidden;
  font-size: 17px;
  line-height: 20px;
  cursor: pointer;

  @include to(2) {
    display: none;
  }

  a {
    line-height: 20px;
  }
}

.modalText {
  text-align: center;
}
