@use 'sass:math';

@import 'colors.scss';

$transition: all 350ms ease;

.container {
  position: relative;
  z-index: 2;
  flex: 1 0 50%;
  padding: 10px 5px;
  overflow: hidden;
  transition: $transition;
  border: 1px solid $grey75;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: $transition;
    border: 4px solid transparent;
  }

  &.selected {
    border-color: $grey100;
  }

  &:first-child,
  &:first-child::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & ~ & {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.radio {
  $diameter: 16px;

  position: relative;
  width: $diameter;
  height: $diameter;
  margin: 0 auto 10px;
  transition: $transition;
  border: 1px solid;
  border-radius: 50%;
  background: $white;

  input {
    opacity: 0;
  }

  &::before {
    $innerDiameter: 10px;

    content: '';
    position: absolute;
    top: calc(50% - #{math.div($innerDiameter, 2)});
    left: calc(50% - #{math.div($innerDiameter, 2)});
    width: $innerDiameter;
    height: $innerDiameter;
    transition: $transition;
    border-radius: 50%;
    background: $white;
  }
}

.amount {
  margin: 0;
  font-weight: 600;
}

.description {
  margin: 0;
  font-size: 13px;
}

.bonus {
  margin: 0;
  font-size: 13px;
  font-style: italic;
  font-weight: 600;
}

@each $theme, $themeColors in $themes {
  $secondary: map-get($themeColors, 'secondary');
  $background: transparentize($secondary, 0.75);

  .#{$theme} {
    &.container.selected::before {
      border-color: $background;
      background: transparentize($secondary, 0.95);
    }

    .radio {
      border-color: $secondary;
      background: $white;
    }

    &.selected .radio {
      background: $background;
    }

    &.selected .radio::before {
      background: $secondary;
    }

    .bonus {
      color: $secondary;
    }
  }
}
