@import 'colors.scss';

.specificityWrapper {
  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    min-width: 100%;
    margin-bottom: 1em;
    border-radius: 10px;
    background-color: #ffffff;
    color: #000000;

    &:hover {
      background-color: $grey200;
    }
  }

  .container.container {
    height: 50px;
    padding: 0 16px;
  }

  .logo {
    width: 22px;
    margin-right: 0.5em;
  }

  .buttonContent {
    font-size: 16px;
    font-weight: 400;
  }
}
