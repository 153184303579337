@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid $border;
}

.detailsContainer {
  padding: 0 20px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
}

.date {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #77757b;
}

.image {
  display: flex;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--color-cool-purple-100);
  }
}
