.container {
  // display: grid;
  // grid-template-columns: 22px 1fr;
  // align-items: start;
  display: flex;
  gap: 16px;
  // padding: 0 14px 18px;

  p {
    // margin-top: -3px;
    // font-size: 15px;
    // letter-spacing: 0.2px;
    // line-height: 24px;

    color: #000000;
    font-family: Averta;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }
}
