@import 'breakpoints.scss';
@import 'colors.scss';

.container.container {
  display: flex;
  position: relative;
  align-items: stretch;

  @include from(3) {
    min-height: 213px;
    padding: 0;
  }

  @include to(2) {
    padding-bottom: 17px;
  }
}

.media {
  @include from(3) {
    width: 213px;
    margin-right: 20px;
  }

  @include to(2) {
    width: 80px;
    height: 80px;
    margin-right: 12px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  @include from(3) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  @include to(2) {
    margin-right: 12px;
    border-radius: 5px;
  }
}

.smallImage {
  @include from(3) {
    display: none;
  }
}

.largeImage {
  @include to(2) {
    display: none;
  }
}

.title {
  margin-bottom: 8px;
  color: $grey800;
  line-height: 29px;

  @include from(3) {
    font-size: 28px;
  }

  @include to(2) {
    font-size: 24px;
  }
}

.details {
  display: flex;
  flex: 2 0;
  flex-direction: column;

  @include from(3) {
    margin-right: 20px;
    padding: 20px 0;
  }
}

.stats {
  @include to(2) {
    margin-bottom: 30px;
  }
}

.dates {
  margin-top: auto;

  @include to(2) {
    display: none;
  }
}

.plans {
  display: flex;
  align-items: stretch;
  justify-content: right;

  @include from(3) {
    flex: 1.5;
    align-self: center;
    padding-right: 20px;
  }

  .details > & {
    @include from(3) {
      display: none;
    }
  }

  .details ~ & {
    @include to(2) {
      display: none;
    }
  }
}

.bet {
  text-align: right;
  white-space: nowrap;

  @include from(3) {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    color: $grey800;
    font-size: 17px;
  }

  @include to(2) {
    position: absolute;
    right: 12px;
    bottom: 17px;
    color: $grey500;
  }
}
