@import 'breakpoints.scss';

.container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.oldAmount {
  text-decoration: line-through;
  color: #77757b;
  margin-right: 10px;
}
