@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border;
  padding: 16px 0;
}
