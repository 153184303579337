@import 'utils.scss';
@import 'breakpoints.scss';

.ctaButton.ctaButton {
  padding: 16px 29px;

  @include to(2) {
    width: 306px;
    margin-bottom: 0;
  }

  @include from(3) {
    width: auto;
  }
}

.variantSecondary.variantSecondary {
  --border-size: 1px;

  padding: calc(16px - var(--border-size)) 24px;
  border: var(--border-size) solid var(--color-purple-400);
  background-color: transparent;
  color: var(--color-purple-400);
}
