@import 'breakpoints.scss';
@import 'colors.scss';

.passwordForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  gap: 20px;
}

.passwordRules {
  color: #330b66;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.passwordHeader {
  h4 {
    margin: 0;
    padding: 0;
    color: #330b66;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}
