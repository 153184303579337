.heading {
  text-align: left;
}

.pressLogoHeading {
  letter-spacing: normal;
  text-align: left;
  text-transform: none;
}

.secureCheckout {
  grid-template-columns: auto 1fr;
  margin-left: 10px;
}

.passwordHeading {
  line-height: 22.5px;
  text-align: left;
}

.passwordRules {
  margin: 1.5rem 0 0.75rem;
  font-size: 14px;
  font-weight: 400;
}

.passwordForm input {
  height: 50px;
  padding: 16px;
  border: 1px solid #cdccd3;
  border-radius: 5px;
}

.savePayment {
  align-self: flex-start;
  margin-bottom: 0;
  margin-left: 13px;
}

.savePaymentLabel {
  margin-left: 0.5em;
}

.logos {
  --row-gap: 32px;

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-evenly;
  margin-top: 25px;

  /*
    iOS Safari < 14.8 doesn't support row-gap for flex so we're using
    bottom margin on the imgs. this pulls up the gap under the last row.
    */
  margin-bottom: calc(var(--row-gap) * -1);

  img {
    @media screen and (width <= 359px) {
      margin: 0 16px var(--row-gap);
    }

    margin: 0 8px var(--row-gap);
  }
}
