@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  position: relative;
}

.detailsGradient {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 70%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: linear-gradient(
    to bottom,
    rgb(93 92 92 / 0%),
    rgb(20 5 28 / 100%)
  );
}

.detailsContainer {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  padding: 0 14px 20px;
  color: $white;
}

.avatar {
  border: 1px solid $white;
}

.hostContainer {
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.title {
  font-size: 25px;
  line-height: 30px;
}

.dates {
  margin-top: 5px;
  font-size: 14px;
  line-height: 16px;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.stat {
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: auto auto;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.smallImage {
  @include from(3) {
    display: none;
  }
}

.largeImage {
  @include to(2) {
    display: none;
  }
}
