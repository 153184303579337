@import 'utils.scss';
@import 'breakpoints.scss';

.container {
  display: flex;

  @include to(1) {
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    width: 340px;
    height: auto;
  }

  @include from(2) {
    // NOTE: fixes IE11 bug where content size is not respected.
    // flex: 0 0 auto;
    // padding: 0 22.5px;

    > * {
      flex-direction: row;
      justify-content: center;
      gap: 22px;
      width: 335px;
      height: 142px;
    }
  }
}

.image {
  width: 138px;
  height: 138px;
  margin-right: 22px;

  @include to(1) {
    margin-right: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 178px;
  gap: 13px;
}

.title {
  flex-wrap: wrap;
  margin: 0;
  color: #330b66;
  font-size: 25px;
  line-height: 28px;
}

.description {
  flex-wrap: wrap;
  margin: 0;
  color: #525157;
  font-size: 18px;
  line-height: 25px;
}
