@import 'colors.scss';

.modalDialog {
  max-width: 375px;
}

.modalContent {
  margin-top: 15px;
  color: $grey900;
  line-height: 24px;
  text-align: center;
}

.cta {
  margin-top: 10px;
  font-size: 18px;
}
